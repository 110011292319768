<template>
  <div class="footer">
<!--    <a href="http://www.java1234.vip" target="_blank">www.java1234.vip</a>-->
  </div>
</template>

<script>

</script>

<style lang="scss" scoped>
.footer{
  padding: 20px;
  display: flex;
  align-items: center;
}
</style>
