<template>
  <el-menu
    active-text-color="#ffd04b"
    background-color="#2d3a4b"
    class="el-menu-vertical-demo"
    default-active="home"
    text-color="#fff"
    router
  >
    <el-menu-item index="home">
      <el-icon><home-filled /></el-icon>
      <span>首页</span>
    </el-menu-item>

<!--    <el-menu-item index="user">-->
<!--      <el-icon><user /></el-icon>-->
<!--      <span>管理</span>-->
<!--    </el-menu-item>-->

    <el-sub-menu index="3">
      <template #title >
        <el-icon><user /></el-icon>
        <span>医生管理</span>
      </template>
      <el-menu-item index="bigType">
        <el-icon><user /></el-icon>
        <span>中医院医生管理</span>
      </el-menu-item>
      <el-menu-item index="smallType">
        <el-icon><user /></el-icon>
        <span>乡镇医生管理</span>
      </el-menu-item>
    </el-sub-menu>





    <el-sub-menu index="product">
      <template #title >
<!--        <el-icon><tickets /></el-icon>-->
        <el-icon><DocumentCopy /></el-icon>
        <span>转诊单管理</span>
      </template>

      <el-menu-item index="list_zyy_to_city">
        <el-icon><tickets /></el-icon>
        <span>中医院 -> 市级医院</span>
      </el-menu-item>

      <el-menu-item index="list_zyy_to_town">
        <el-icon><tickets /></el-icon>
        <span>中医院 -> 乡镇</span>
      </el-menu-item>

      <el-menu-item index="list_town_to_zyy">
        <el-icon><tickets /></el-icon>
        <span>乡镇 -> 中医院</span>
      </el-menu-item>

      <el-menu-item index="list_city_to_zyy">
        <el-icon><tickets /></el-icon>
        <span>市级医院 -> 中医院</span>
      </el-menu-item>

      <el-menu-item index="product">
        <el-icon><tickets /></el-icon>
        <span>转诊单总表管理</span>
      </el-menu-item>

    </el-sub-menu>



   <el-menu-item index="order">
<!--     <el-icon><tickets /></el-icon>-->
     <el-icon><DataBoard /></el-icon>
     <span>资讯管理</span>
   </el-menu-item>

    <el-menu-item index="feedback3">
<!--      <el-icon><tickets /></el-icon>-->
      <el-icon><Notebook /></el-icon>
      <span>意见反馈管理</span>
    </el-menu-item>

    <el-sub-menu index="11">
      <template #title >
        <el-icon><management /></el-icon>
        <span>系统管理</span>
      </template>
      <el-menu-item index="modifyPassword">
        <el-icon><edit /></el-icon>
        <span>修改密码</span>
      </el-menu-item>
      <el-menu-item >
        <el-icon @click="logout"><switch-button /></el-icon>
        <span @click="logout">安全退出</span>
      </el-menu-item>
    </el-sub-menu>


  </el-menu>
</template>

<script setup>
import {HomeFilled,User,Tickets,Goods,DocumentAdd,Management,Setting,Edit,SwitchButton} from '@element-plus/icons-vue'

import {useStore} from 'vuex'
const store=useStore();


const logout=()=>{
  store.dispatch('logout')
}

</script>

<style scoped>

</style>
